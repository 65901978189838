import './App.css';
import { useState } from 'react';

const getImages = async (query) => {
  const url = "https://serverless-api.pedrocabido.workers.dev"
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({query}),
    headers: {
      'Content-Type': 'application/json'
      }
    })

    return response.json()
}

function App() {
  const [query, setQuery] = useState('');
  const [images, setImages] = useState([]);

  const updateQuery = (evt) => setQuery(evt.target.value);

  const search = async () => {
    const results = await getImages(query);
    setImages(results);
  }

  return (
    <div className="App">
      <div class="form">
        <input id="query" type="text" onChange={updateQuery} placeholder="Search query" />
        <button onClick={search}>Search</button>
      </div>

      {images.map(image => (
        <div class="image">
          <img src={image.url} alt={query} />
        </div>
      ))}
    </div>
  );
}

export default App;
